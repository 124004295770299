import { getImageProps } from "next/image";
import React from "react";
import { IPost } from "@/types/wordpress-types";

interface BannerImageProps {
  banner: IPost;
  location?: "top" | "bottom";
  type?: "banner" | "mpu";
}

export function BannerImage({
  banner,
  location,
  type = "banner",
}: BannerImageProps) {
  const MIN_WIDTH = 550;

  const sizes = type === "banner" ? "100vw" : "(max-width: 500px) 100vw, 33vw";

  const common = {
    alt: `${banner?.post_title} banner`,
    sizes: sizes,
    quality: 60,
  };

  const {
    props: { srcSet: desktop, ...rest },
  } = getImageProps({
    ...common,
    width: type === "banner" ? 1200 : 300,
    height: type === "banner" ? 170 : 250,
    src: `${process.env.NEXT_PUBLIC_API_URL}${banner?.acf?.desktop_image ? banner?.acf?.desktop_image : ""}`,
  });

  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...common,
    width: type === "banner" ? MIN_WIDTH : 300,
    height: type === "banner" ? MIN_WIDTH : 250,
    src: `${process.env.NEXT_PUBLIC_API_URL}${banner?.acf?.mobile_image ? banner?.acf?.mobile_image : ""}`,
  });

  if (!banner) return null;

  return (
    <picture className="relative w-full h-auto">
      <source media={`(min-width: ${MIN_WIDTH}px)`} srcSet={desktop} />
      <source media={`(max-width: ${MIN_WIDTH - 1}px)`} srcSet={mobile} />
      <img
        {...rest}
        className={"object-center object-cover"}
        style={{ width: "100%", height: "auto" }}
        alt={`${banner?.post_title} banner`}
      />
    </picture>
  );
}
